
/* eslint-disable */

import Cloudfun, { defineComponent, ref, reactive, watch, computed, onMounted, SortOrder, Sorting, Operator } from "@cloudfun/core";
import { useRouter } from 'vue-router'
import { helper } from "@/utils/helper"
import CfGrid, { GridOptions, GridColumnOptions } from "@/components/grid/Main.vue";
// import CfTabsBar from "@/components/tabs-bar/Main.vue";
import CfTabs from "@/components/tabs/Main.vue";
import CfSearchBar from "@/components/search-bar/Main.vue";
import CfToolBar from "@/components/tool-bar/Main.vue";
import FileUpload, { VueUploadItem } from 'vue-upload-component';
import ConfirmRemoveModal from "@/components/modal/confirm-remove/Main.vue";
import { POSITION, useToast } from "vue-toastification";

export default defineComponent({
  components: {
    // CfTabsBar,
    CfGrid,
    CfToolBar,
    CfTabs,
    CfSearchBar,
    FileUpload,
    ConfirmRemoveModal
  },
  setup() {
    const router = useRouter();
    const model = Cloudfun.current?.model;
    const permissions: any = Cloudfun.current?.user.Permissions;
    const grid = ref<any>();
    const tabs: any = ref([])
    const selectedRow = ref<any | null>(null);
    const loading = ref(false);
    const uploader = model?.state.batchUploader;
    const imageType: any = [{
      name: "Image", value: 0

    }, {
      name: "Dicom", value: 1
    }];
    const modelType: any = [{
      name: "classification", value: 0
    }, {
      name: "Grade", value: 1
    }, {
      name: "Regression", value: 2
    }]

    const gridOptions: GridOptions = {
      columns: [
        { field: 'CreatedTime', title: 'Time', width: 200, sortable: false, formatter({ row }) { return helper.formatDate(row.CreatedTime, "yyyy/MM/dd HH:mm") } },
        { field: 'Name', title: 'Model Name', width: 300, sortable: false },
        { field: 'FileType', title: 'Image  Type', width: 300, sortable: false, formatter({ row }) { return imageType.find(e => e.value === row.FileType)?.name } },
        { field: 'UpdatedTime', title: 'Uploaded(Time)', width: 300, sortable: false },
        { field: 'ModelType', title: 'Model Type', width: 300, sortable: false, formatter({ row }) { return modelType.find(e => e.value === row.ModelType)?.name } },
        {
          field: 'Owner', title: 'Owner', width: 300, sortable: false,
          formatter: ({ cellValue }) => cellValue ? cellValue : "無",

        },
        // {
        //   field: 'Published', title: 'Published',
        //   cellRender: {
        //     name: '$switch',
        //     props: { openLabel: '是', closeLabel: '否', disabled: true },
        //   }
        // }
      ],
      promises: {
        query: model
          ? (params) => new Promise((resolve) => {
            if (grid.value.keyword) params.keyword = grid.value.keyword
            params.sortings = [new Sorting('CreatedTime', SortOrder.Descending)];
            params.condition!.and("Connectable", Operator.Equal);
            params.pageSize = 20;
            model.dispatch('serverModel/query', params).then(resp => {
              resolve(resp);
            });
          }) : undefined,
      }
    }

    const onGridCellClick = ({ row, column }: { row: any, column: GridColumnOptions }) => {

      selectedRow.value = row;
    }

    const onSearch = (value: string) => {
      grid.value.keyword = value;
      grid.value.reload();
    }
    const uploadFile: any = ref([]);
    const uploadModel = (event: any) => {
      // if (!event?.target?.files?.length) return;
      // if (!(event.target.files instanceof FileList)) return;
      loading.value = true;
      const formData = new FormData();
      formData.append("file", event?.target?.files[0])
      model?.dispatch('serverModel/upload', { formData }).then(() => {
        useToast().info(
          `上傳成功!`
          , { position: POSITION.TOP_CENTER}
        )
      });

    }

    const removeModal = reactive({
      visible: false,
      row: {} as any,
      show() {
        removeModal.visible = true;
        removeModal.row = selectedRow.value!;
      },
      async submit(item: any) {
        try {
          await model?.dispatch("serverModel/delete", item.Id);
          selectedRow.value = null;
          removeModal.visible = false;
          grid.value.reload();
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    })
    watch(() => model?.state["serverModel"]["reloadUI"], value => {
      if (!value) return;
      console.log('reload UI!!!!!!')
      grid.value.reload();
      model!.state["serverModel"]["reloadUI"] = false;
    })

    onMounted(() => {
      tabs.value = [
        { name: "PROJECT", href: "/project", current: false, permission: permissions.includes('Project') },
        { name: "ALBUM", href: "/album", current: false, permission: permissions.includes('Album') },
        { name: "MODEL", href: "/model", current: true, permission: permissions.includes('Model') }
      ].filter((tab: any) => {
        return tab.permission === true;
      });
    })

    return {
      grid,
      uploadFile,
      gridOptions,
      selectedRow,
      loading,
      onGridCellClick,
      onSearch,
      uploadModel,
      tabs,
      removeModal,
      toolbar: [
        {
          tooltipMsg: "Sharing group",
          icon: "icon-user",
        },
        {
          tooltipMsg: "Delete",
          icon: "icon-delete",
        },
        {
          tooltipMsg: "Rename",
          icon: "icon-edit",
        },
      ],
    };
  },
  methods: {},
});
